import { defineStore } from 'pinia'
import { computed, ref, watch, type Ref } from 'vue'
import { ScrollState } from '@/Enum/ScrollState'
import type { ClipResource, PodcastResource, PodcasterResource, CategoryResource } from '@/types'
import useApi from '@/Composables/useApi'
import { useCategoryStore } from '@/Stores/category'

export const defineClipStore = (
    podcast: PodcastResource | undefined = undefined,
    podcaster: PodcasterResource | undefined = undefined
): ReturnType<typeof useClipStore> => {
    const store = useClipStore()
    store.setPodcaster(podcaster)
    store.setPodcast(podcast)
    store.resetValues()
    return store
}

export const useClipStore = defineStore('clip', () => {
    const categoryStore = useCategoryStore()
    const clips: Ref<ClipResource[]> = ref([])
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)
    const currentPage = ref(0)
    const podcast: Ref<PodcastResource | undefined> = ref<PodcastResource | undefined>(undefined)
    const podcaster: Ref<PodcasterResource | undefined> = ref<PodcasterResource | undefined>(undefined)
    const clipCount = ref(0)
    const currentYear = ref(new Date().getFullYear())

    const hasClips = computed(() => clips.value.length > 0)
    const isLoading = ref(false);

    const { getJson } = useApi()

    const fetchClips = async (endpoint: string, parameter: string, url_addition: string = '') => {
        try {
            scrollState.value = ScrollState.Requesting
            isLoading.value = true

            currentPage.value += 1
            const categoryId = categoryStore.selectedCategory?.id
            const url = `/api/${endpoint}/${parameter}/clips?page=${currentPage.value}${categoryId ? `&category=${categoryId}` : ''}${url_addition ? `&${url_addition}` : ''}`
            const response = await getJson(url)
            const responseClips = Array.isArray(response.data)
                ? response.data
                : Object.values(response.data);

            clips.value = [...clips.value, ...responseClips]
            clipCount.value = response.total

            if (response.current_page >= response.last_page) {
                scrollState.value = ScrollState.Ended
            } else {
                scrollState.value = ScrollState.Idle
            }
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
        } finally {
            isLoading.value = false
        }
    }

    const fetchAllClips = async () => {
        try {
            scrollState.value = ScrollState.Requesting
            currentPage.value += 1
            const categoryId = categoryStore.selectedCategory?.id
            const url = `/api/clips?page=${currentPage.value}${categoryId ? `&category=${categoryId}` : ''}`
            const response = await getJson(url)

            clips.value = [...clips.value, ...response.data]
            clipCount.value = response.total
            if (response.current_page >= response.last_page) {
                scrollState.value = ScrollState.Ended
            } else {
                scrollState.value = ScrollState.Idle
            }
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
        }
    }

    const fetchTopRatedClips = async () => {
        if (scrollState.value === ScrollState.Idle && !isLoading.value) {
            try {
                scrollState.value = ScrollState.Requesting
                isLoading.value = true
                currentPage.value += 1
                const url = `/api/clips/top-rated?page=${currentPage.value}`
                const response = await getJson(url)

                clips.value = [...clips.value, ...response.data || []]
                clipCount.value = clips.value.length

                if (!response.last_page || response.current_page >= response.last_page) {
                    scrollState.value = ScrollState.Ended
                } else {
                    scrollState.value = ScrollState.Idle
                }
            } catch (e: any) {
                console.error('Error fetching top rated clips:', e)
                scrollState.value = ScrollState.Failed
                currentPage.value -= 1
            } finally {
                isLoading.value = false
            }
        }
    }

    const fetchEntityClips = async (entity: 'podcaster' | 'podcast', entityId: string, url_addition: string = '') => {
        if (scrollState.value === ScrollState.Idle) {
            await fetchClips(entity, entityId, url_addition)
        }
    }

    async function loadMoreClips(isForUser = false) {
        if (scrollState.value === ScrollState.Idle) {
            if (podcast.value) {
                await fetchEntityClips('podcast', podcast.value.id)
            } else if (podcaster.value) {
                await fetchEntityClips('podcaster', podcaster.value.id)
            } else {
                await fetchAllClips()
            }
        }
    }

    const setPodcaster = (newPodcaster: PodcasterResource | undefined) => {
        podcaster.value = newPodcaster
    }

    const setPodcast = (newPodcast: PodcastResource | undefined) => {
        podcast.value = newPodcast
    }

    const resetValues = () => {
        clips.value = []
        currentPage.value = 0
        scrollState.value = ScrollState.Idle
        clipCount.value = 0
    }

    const jumpToYear = async (year: number) => {
        currentYear.value = year
        resetValues()
        await loadMoreClips()
    }

    const availableYears = computed(() => {
        const years = new Set(clips.value.map((clip) => new Date(clip.timestamp * 1000).getFullYear()))
        return Array.from(years).sort((a, b) => b - a)
    })

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    // Watch for changes in the selected category
    watch(
        () => categoryStore.selectedCategory,
        () => {
            resetValues()
            if (podcast.value) {
                fetchEntityClips('podcast', podcast.value.id)
            } else if (podcaster.value) {
                fetchEntityClips('podcaster', podcaster.value.id)
            } else {
                fetchAllClips()
            }
        }
    )

    return {
        fetchEntityClips,
        loadMoreClips,
        resetValues,
        clips,
        scrollState,
        currentPage,
        clipCount,
        isWaiting,
        failed,
        setPodcaster,
        setPodcast,
        jumpToYear,
        availableYears,
        hasClips,
        isLoading,
        fetchTopRatedClips,
    }
})
