import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { computed, ref, watch, onMounted } from 'vue'
import useApi from '@/Composables/useApi'
import type { PodcasterResource, PodcastResource } from '@/types'
import { ScrollState } from '@/Enum/ScrollState'
import { useCategoryStore } from '@/Stores/category'

const { getJson } = useApi()

export const definePodcastStore = (podcaster: PodcasterResource | undefined = undefined): ReturnType<typeof usePodcastStore> => {
    const store = usePodcastStore()

    if (podcaster && (!store.podcaster || store.podcaster.id !== podcaster.id)) {
        store.setPodcaster(podcaster)
        store.resetValues()

        // Force fetch on new podcaster
        store.fetchPodcasts(true)
    }

    return store
}

export const usePodcastStore = defineStore('podcast', () => {
    const categoryStore = useCategoryStore()
    const podcasts: Ref<PodcastResource[]> = ref<PodcastResource[]>([])
    const podcaster: Ref<PodcasterResource | undefined> = ref<PodcasterResource>()
    const currentPage = ref(0)
    const totalPodcasts = ref(0)
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)
    const isInitialLoad = ref(true)
    const isSetup = ref(true)

    const setFailed = (value: boolean) => {
        scrollState.value = value ? ScrollState.Failed : ScrollState.Idle
    }

    const fetchPodcasts = async (force = false) => {
        try {
            // Don't fetch if already requesting
            if (scrollState.value === ScrollState.Requesting) {
                return;
            }

            scrollState.value = ScrollState.Requesting;

            // Only reset page if forcing refresh
            if (force) {
                currentPage.value = 1;
                podcasts.value = []; // Clear existing podcasts when forcing
            } else {
                currentPage.value += 1;
            }

            if (!podcaster.value) {
                throw new Error('Podcaster not found');
            }

            const selectedCategory = categoryStore.selectedCategory;
            const endpoint = selectedCategory
                ? `/api/${podcaster.value.id}/${selectedCategory.id}/podcasts`
                : `/api/${podcaster.value.id}/podcasts`;

            const response = await getJson(`${endpoint}?page=${currentPage.value}`);

            // Always append new podcasts unless forcing refresh
            if (force) {
                podcasts.value = response.data;
            } else {
                podcasts.value = [...podcasts.value, ...response.data];
            }

            totalPodcasts.value = response.total;

            // Only set to ended if we've loaded all podcasts
            scrollState.value = podcasts.value.length >= response.total
                ? ScrollState.Ended
                : ScrollState.Idle;

        } catch (e: any) {
            scrollState.value = ScrollState.Failed;
        }
    };

    const loadMorePodcasts = async () => {
        // Only load more if we haven't reached the end and aren't currently loading
        if (scrollState.value !== ScrollState.Ended &&
            scrollState.value !== ScrollState.Requesting) {
            await fetchPodcasts(false);
        }
    };

    const setPodcaster = (newPodcaster: PodcasterResource | undefined) => {
        podcaster.value = newPodcaster
    }

    const resetValues = () => {
        podcasts.value = []
        currentPage.value = 0
        scrollState.value = ScrollState.Idle
        isInitialLoad.value = true
    }

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    watch(
        () => categoryStore.selectedCategory,
        (newCategory, oldCategory) => {
            if (newCategory?.id !== oldCategory?.id) {
                if (!isSetup.value) {
                    resetValues()
                    fetchPodcasts(true)
                } else if (isSetup.value && newCategory) {
                    isSetup.value = false
                } else if (isSetup.value) {
                    categoryStore.selectedCategory = oldCategory
                }
            }
        },
    )

    return {
        podcasts,
        podcaster,
        fetchPodcasts,
        setPodcaster,
        resetValues,
        loadMorePodcasts,
        isWaiting,
        failed,
        totalPodcasts,
        setFailed,
        scrollState,
    }
})
