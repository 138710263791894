import useApi from '@/Composables/useApi'
import { usePodcasterStore } from '@/Stores/podcaster'
import type {
    ClipFinderConversationResource,
    ClipFinderMessageResource,
    ClipResource,
    ClipSuggestionResource,
    LLMModelResource,
    PodcasterResource,
} from '@/types'

import { defineStore, storeToRefs } from 'pinia'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import { useUserStore } from '@/Stores/user'
import { Ziggy } from './../ziggy'
import { route } from 'ziggy-js'
import { router } from '@inertiajs/vue3'

const { getJson, postJson, patchJson } = useApi()

export enum ScrollState {
    Idle = 'idle',
    Requesting = 'requesting',
    Waiting = 'waiting',
    Shown = 'shown',
    Ended = 'end',
    Failed = 'failed',
}

export const defineConversationStore = (
    initialConversation: ClipFinderConversationResource | undefined = undefined,
    shared: boolean | undefined = false
): ReturnType<typeof useConversationStore> => {
    const store = useConversationStore()
    store.load(initialConversation, shared || false)
    store.fetchHistory()
    return store
}

export const useConversationStore = defineStore('conversation', () => {
    const podcasterStore = usePodcasterStore()
    const userStore = useUserStore()

    const { loggedIn } = storeToRefs(userStore)
    const { selectedPodcasters } = storeToRefs(podcasterStore)

    const conversationId: Ref<string | null> = ref<string | null>(null)
    const today = new Date()
    const fromYearCalculator = () => today.getMonth() > 6 ? today.getFullYear() : today.getFullYear() - 1
    const fromYear: Ref<number> = ref(fromYearCalculator())
    const tillYear: Ref<number> = ref(today.getFullYear())

    const conversations: Ref<ClipFinderConversationResource[]> = ref<ClipFinderConversationResource[]>([])
    const messages: Ref<ClipFinderMessageResource[]> = ref([])
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)
    const orchestrating: Ref<Boolean> = ref<Boolean>(false)

    const configurationSlideOver = ref(false)
    const shared = ref<boolean>(false)
    const messageCallbacks: Ref<Function[]> = ref([])
    const clipCallbacks: Ref<Function[]> = ref([])

    const loadingText :Ref<String> = ref(null)

    const searchEarlierClips = async (message: ClipFinderMessageResource) => {
        let text = `No more results found in the range ${fromYear.value} - ${tillYear.value}. Now searching for clips from `
        let tempFromYear = fromYear.value
        let tempTillYear = tillYear.value
        tillYear.value = fromYear.value
        loadingText.value = text + fromYear.value
        scrollState.value = ScrollState.Requesting

        let from_year = (message.from_year ? message.from_year : fromYear.value) - 1
        fromYear.value = from_year

        await patchConversation({ from_year: from_year, till_year: from_year })

        await sendMessage(message.original_question)

        fromYear.value = tempFromYear
        tillYear.value = tempTillYear

        await patchConversation({ from_year: fromYear.value, till_year: tillYear.value })
    }

    const fetchHistory = async () => {
        if (conversations.value.length > 0) {
            return
        }

        conversations.value = await getJson('/api/conversations')
    }

    const load = (initialConversation: ClipFinderConversationResource | undefined, initialShared: boolean) => {
        shared.value = initialShared

        if (!initialConversation) {
            conversationId.value = null
            messages.value = []
            fromYear.value = fromYearCalculator()
            tillYear.value = new Date().getFullYear()
            return
        }

        conversationId.value = initialConversation.id
        messages.value = initialConversation.messages
        fromYear.value = initialConversation.from_year
        tillYear.value = initialConversation.till_year
    }

    watch(conversationId, () => {
        if (!conversationId.value) {
            return
        }

        if (loggedIn.value) {
            const channel = 'clip-conversations.' + conversationId.value
            window.Echo.leave(channel)
            window.Echo.private('clip-conversations.' + conversationId.value)
                .listen('.clip-updated', onClipUpdated)
                .listen('.message-updated', onMessageUpdated)
        } else {
            const channel = 'clip-conversations.' + conversationId.value
            window.Echo.leave(channel)
            window.Echo.channel('session-clip-conversations.' + conversationId.value)
                .listen('.clip-updated', onClipUpdated)
                .listen('.message-updated', onMessageUpdated)
        }

        navigateToCurrentConversation()
    })

    async function onMessageUpdated(e: ClipFinderMessageResource) {
        const current: ClipFinderMessageResource[] = messages.value
        const response = await getJson(`/api/messages/${e.id}`)

        for (const key in current) {
            const currentMessage = current[key]

            if (currentMessage.id === e.id) {
                current[key] = {
                    ...currentMessage,
                    ...response,
                }

                if (response.suggestions.length > 0) {
                    scrollState.value = ScrollState.Ended
                } else {
                    scrollState.value = ScrollState.Waiting
                }

                messageCallbacks.value.forEach((callback) => callback())
                messageCallbacks.value = []

                return
            }
        }
    }

    async function onClipUpdated(e: { id: string; message_id: string }) {
        const current: ClipFinderMessageResource[] = messages.value
        const response: ClipResource = await getJson(`/api/clips/${e.id}`)

        for (const key in current) {
            if (current[key].id === e.message_id) {
                for (const clipKey in current[key].clips) {
                    let currentClip = current[key].clips[clipKey]

                    if (currentClip.id === e.id) {
                        current[key].clips[clipKey] = {
                            ...currentClip,
                            ...response,
                        }

                        clipCallbacks.value.forEach((callback) => callback())
                        clipCallbacks.value = []

                        return
                    }
                }
            }
        }

        messages.value = current
    }

    function addMessages(newMessages: ClipFinderMessageResource[]) {
        messages.value.push(...newMessages)
    }

    async function sendMessage(question: string, alwaysThrow: Boolean = false) {
        return sendMessageToApi(question, conversationId.value, selectedPodcasters.value, alwaysThrow)
    }

    async function sendMessageToApi(
        question: string,
        conversationUuid: string | null,
        podcasters: Array<PodcasterResource>,
        alwaysThrow: Boolean = false
    ) {
        scrollState.value = ScrollState.Idle

        if (podcasters.length === 0) {
            return Promise.reject('No podcaster selected')
        }

        try {
            scrollState.value = ScrollState.Requesting
            const { response, headers } = await postJson(
                `/api/conversations`,
                {
                    from: fromYear.value,
                    till: tillYear.value,
                    podcasters: podcasters.map((p: PodcasterResource) => p.id),
                    question: question,
                },
                {
                    'X-Conversation-Id': conversationUuid || '',
                }
            )

            conversationId.value = headers.get('X-Conversation-Id') || null
            addMessages([response as ClipFinderMessageResource])

            addOrUpdateConversation(question)

            return conversationId.value
        } catch (e: any) {
            if (e.status && e.status === 422) {
                const errors = await e.json()

                scrollState.value = ScrollState.Idle
                return Promise.reject(errors)
            }

            if (alwaysThrow) {
                throw e
            }

            scrollState.value = ScrollState.Failed
            return
        }
    }

    async function updateParticipants(podcasters: string[]) {
        const { response } = await postJson(`/api/conversations/${conversationId.value}/participants`, {
            participants: podcasters,
        })

        const updatedConversation: ClipFinderConversationResource = response as ClipFinderConversationResource

        for (const i in conversations.value) {
            if (conversations.value[i].id === updatedConversation.id) {
                conversations.value[i].participants = updatedConversation.participants
            }
        }
    }

    function addOrUpdateConversation(question: string) {
        if (
            conversations.value.filter((conversation: ClipFinderConversationResource): boolean => conversation.id === conversationId.value).length > 0
        ) {
            for (const i in conversations.value) {
                if (conversations.value[i].id === conversationId.value) {
                    conversations.value[i].excerpt = question
                    conversations.value[i].updated_at = new Date().toISOString()
                }
            }

            // Re-sort the conversations
            conversations.value = conversations.value.sort((a, b) => new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf())
            return
        }

        conversations.value.unshift({
            id: conversationId.value,
            participants: selectedPodcasters.value,
            excerpt: question,
            messages_count: 2,
            updated_at: Date.now(),
        } as ClipFinderConversationResource)
    }

    async function continueBySuggestedTopic(suggestion: ClipSuggestionResource) {
        suggestion.selected = true

        await Promise.all([postJson(`/api/clips/suggestions/${suggestion.id}`), sendMessage(suggestion.topic)])
    }

    async function loadMoreClips() {
        if (scrollState.value === ScrollState.Ended) {
            return
        }

        if (messages.value.length === 0) {
            return
        }

        const message: ClipFinderMessageResource = messages.value[messages.value.length - 1]

        if (!message) {
            scrollState.value = ScrollState.Ended
            return
        }

        if (message.suggestions.length > 0) {
            scrollState.value = ScrollState.Ended
            return
        }

        await runAgent(message.id)
    }

    async function runAgent(messageId: string) {
        await orchestrate(messageId, 'continue')
    }

    async function orchestrate(messageId: string, endpoint: string) {
        if (shared.value) {
            return
        }

        if (orchestrating.value) {
            return
        }

        orchestrating.value = true

        const {
            response: { status },
        } = await postJson(`/api/messages/${messageId}/${endpoint}/`)

        if (status === 'finished') {
            scrollState.value = ScrollState.Ended
        } else {
            scrollState.value = ScrollState.Waiting
        }

        setTimeout(() => (orchestrating.value = false), 500)
    }

    async function retryMessage(messageId: string, callback: Function) {
        messageCallbacks.value.push(callback)

        await orchestrate(messageId, 'retry')
    }

    async function retryClip(clipId: string, callback: Function) {
        clipCallbacks.value.push(callback)

        await postJson(`/api/clips/${clipId}/retry`)
    }

    async function voteClip(clipId: string, vote: Boolean) {
        await postJson(`/api/clips/${clipId}/vote`, { vote: vote })
    }

    async function captionClip(clipId: string, callback: Function) {
        clipCallbacks.value.push(callback)

        await postJson(`/api/clips/${clipId}/caption`)
    }

    function navigateToCurrentConversation() {
        const url = route('clipfinder.conversations.show', [conversationId.value], undefined, Ziggy)
        window.history.pushState({}, '', route('clipfinder.conversations.show', [conversationId.value], undefined, Ziggy))
        router.visit(url, {
            preserveState: true,
            preserveScroll: true,
            replace: true,
        })
    }

    async function updateConversationModel(model: LLMModelResource) {
        await patchConversation({
            llm_model: model.id,
        })
    }

    async function patchConversation(body: Object) {
        if (!conversationId.value) {
            return
        }

        await patchJson('/api/conversations/' + conversationId.value, body)
    }

    async function shareConversation(clipId: string | undefined = undefined): Promise<string> {
        let endpoint = '/api/share/' + conversationId.value

        if (clipId) {
            endpoint += '/' + clipId
        }

        const { response } = await postJson(endpoint)

        return response.id
    }

    const lastMessage: ComputedRef<ClipFinderMessageResource | null> = computed(() => messages.value[messages.value.length - 1] || null)

    return {
        fetchHistory,
        load,
        conversations,
        conversationId,
        lastMessage,
        messages,
        scrollState,
        shared,
        fromYear,
        tillYear,
        configurationSlideOver,
        sendMessage,
        sendMessageToApi,
        loadMoreClips,
        continueBySuggestedTopic,
        retryMessage,
        retryClip,
        voteClip,
        captionClip,
        updateConversationModel,
        patchConversation,
        shareConversation,
        updateParticipants,
        searchEarlierClips
    }
})
