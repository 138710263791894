import type { ConversationStarterResource, PodcasterResource } from '@/types'
import { defineStore } from 'pinia'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import { router } from '@inertiajs/vue3'
import useApi from '@/Composables/useApi'
import { ScrollState } from '@/Enum/ScrollState'
import { useCategoryStore } from '@/Stores/category'
import { Ziggy } from './../ziggy'
import { route } from 'ziggy-js'

const { getJson, postJson } = useApi()

export const definePodcasterStore = async (selectedPodcasters?: Array<string> | undefined) => {
    const store = usePodcasterStore()

    await store.fetchAllPodcasters()
    if (selectedPodcasters) {
        store.selectPodcasters(selectedPodcasters)
    }

    return store
}

export const usePodcasterStore = defineStore('podcaster', () => {
    const open: Ref<Boolean> = ref(false)
    const podcasters: Ref<PodcasterResource[]> = ref<PodcasterResource[]>([])
    const selectedPodcastersIds: Ref<string[]> = ref<string[]>([])
    const yearRange: Ref<{ from: number; till: number }> = ref({ from: new Date().getFullYear(), till: new Date().getFullYear() })
    const scrollState: Ref<ScrollState> = ref<ScrollState>(ScrollState.Idle)
    const currentPage = ref(0)
    const categoryStore = useCategoryStore()
    const podcasterCount = ref(0)
    const selectedCategory = computed(() => categoryStore.selectedCategory)
    const followedPodcasters: Ref<PodcasterResource[]> = ref<PodcasterResource[]>([])
    const isLoading = ref(false)
    const error = ref<string | null>(null)
    const selectedPodcasterWithQuestions = ref<PodcasterResource | null>(null)


    function setSelectedPodcasterWithQuestions(podcaster) {
        selectedPodcasterWithQuestions.value = podcaster
    }

    const handlePodcasterSelection = () => {
        const pathParts = window.location.pathname.split('/')
        const slugsFromUrl = pathParts[1] == "conversations" ? pathParts.slice(2) : pathParts.slice(1)

        if (slugsFromUrl.length > 0 && slugsFromUrl[0] !== 'start') {
            const selectedPodcasters = podcasters.value.filter(p => slugsFromUrl.includes(p.slug)).slice(0, 6)
            if (selectedPodcasters.length > 0) {
                let podcasterIds = selectedPodcasters.map(p => p.id)
                selectedPodcastersIds.value = []
                selectedPodcastersIds.value = [...podcasterIds]
                return
            }
        }

        selectedPodcastersIds.value = podcasters.value
            .map((p: PodcasterResource) => p.id)
    }

    const fetchAllPodcasters = async () => {
        if (podcasters.value.length > 0) {
            return
        }

        try {
            scrollState.value = ScrollState.Requesting

            podcasters.value = await getJson('/api/podcasters')

            handlePodcasterSelection()
            scrollState.value = ScrollState.Idle
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
        }
    }

    const fetchFollowedPodcasters = async () => {
        isLoading.value = true
        error.value = null

        try {
            scrollState.value = ScrollState.Requesting
            const response = await getJson('/api/following')

            // Get the followed podcaster data with question counts
            const followedData = 'data' in response ? response.data : response

            // Merge with full podcaster data to get images while preserving question counts
            followedPodcasters.value = followedData.map(followedPodcaster => {
                const fullPodcaster = podcasters.value.find(p => p.id === followedPodcaster.id)
                return {
                    ...followedPodcaster,
                    picture_url: fullPodcaster?.picture_url,
                    webp_picture_url: fullPodcaster?.webp_picture_url
                }
            })
        }
        catch (e: any)
        {
            scrollState.value = ScrollState.Failed
            followedPodcasters.value = []
        }
        finally {
            isLoading.value = false
        }
    }

    async function toggleFollowPodcaster(podcasterId: string) {
        const url = `/api/follow/${podcasterId}`;
        try {
            scrollState.value = ScrollState.Requesting;

            // Find podcaster before API call
            const podcaster = podcasters.value.find(p => p.id === podcasterId);
            if (!podcaster) return false;

            // Get current questions count if already following
            const currentPodcaster = followedPodcasters.value?.find(p => p.id === podcasterId);
            const questionsCount = currentPodcaster?.questions_count || 0;

            // Optimistically update the UI before API call
            const isCurrentlyFollowing = followedPodcasters.value?.some(p => p.id === podcasterId);

            if (!isCurrentlyFollowing) {
                // Add to followedPodcasters immediately with preserved questions count
                const podcasterToAdd = {
                    ...podcaster,
                    questions_count: questionsCount
                };
                followedPodcasters.value = [podcasterToAdd, ...(followedPodcasters.value || [])].sort((a, b) => a.name.localeCompare(b.name));
            }
            else
            {
                // Store questions count before removing
                const removedPodcasterQuestions = questionsCount;

                // Remove from followedPodcasters immediately
                followedPodcasters.value = followedPodcasters.value?.filter(p => p.id !== podcasterId);

                // Update selected podcaster questions if needed
                if (selectedPodcasterWithQuestions.value?.id === podcasterId) {
                    selectedPodcasterWithQuestions.value = {
                        ...selectedPodcasterWithQuestions.value,
                        questions_count: removedPodcasterQuestions
                    };
                }
            }

            // Make API call
            const response = await postJson(url);
            const { isFollowing } = response.response as { isFollowing: boolean };

            updatePodcasterFollowStatus(podcasterId, isFollowing);
            scrollState.value = ScrollState.Idle;

            return isFollowing;
        } catch (e: any) {
            // Revert optimistic update on error
            const wasFollowing = followedPodcasters.value?.some(p => p.id === podcasterId);
            const podcaster = podcasters.value.find(p => p.id === podcasterId);
            const currentQuestions = selectedPodcasterWithQuestions.value?.questions_count || 0;

            if (wasFollowing) {
                followedPodcasters.value = followedPodcasters.value?.filter(p => p.id !== podcasterId);
            } else if (podcaster) {
                const podcasterToRestore = {
                    ...podcaster,
                    questions_count: currentQuestions
                };
                followedPodcasters.value = [podcasterToRestore, ...(followedPodcasters.value || [])];
            }

            scrollState.value = ScrollState.Failed;
            throw e;
        }
    }

    const updatePodcasterFollowStatus = (podcasterId: string, isFollowing: boolean) => {
        const updatePodcaster = (list: PodcasterResource[]) => {
            const podcaster = list.find(p => p.id === podcasterId);
            if (podcaster) {
                podcaster.is_following = isFollowing;
            }
        };
        updatePodcaster(podcasters.value);
        updatePodcaster(selectedPodcasters.value);
        updatePodcaster(followedPodcasters.value);
    }

    async function loadMorePodcasters() {
        if (scrollState.value === ScrollState.Idle) {
            await fetchPodcasters()
        }
    }

    const fetchPodcasters = async () => {
        return fetchAllPodcasters();
    }

    const fetchPodcasterQuestions = async (podcaster: PodcasterResource) => {
        try {
            scrollState.value = ScrollState.Requesting
            const response = await getJson(`/api/podcasters/${podcaster.id}/questions`)

            const podcasterWithQuestions = {
                ...podcaster,
                questions: response.data
            }

            selectedPodcasterWithQuestions.value = podcasterWithQuestions
            scrollState.value = ScrollState.Idle

            return podcasterWithQuestions
        } catch (e: any) {
            scrollState.value = ScrollState.Failed
            error.value = e.message
        }
    }

    const selectedPodcasters = computed(() => {
        if (!podcasters.value) return []
        return podcasters.value.filter((p) => selectedPodcastersIds.value.includes(p.id))
    })

    const selectPodcasters = (podcasterIds: string[]) => {
        selectedPodcastersIds.value = []
        selectedPodcastersIds.value = [...podcasterIds]

        if (!podcasters.value.some(p => podcasterIds.includes(p.id))) {
            fetchAllPodcasters()
        }
    }

    const selectAllPodcasters = () => {
        const podcasterIds = podcasters.value.map(p => p.id)
        selectPodcasters(podcasterIds)
    }

    const trainingDate: ComputedRef<Date> = computed((): Date => new Date(selectedPodcasters.value[0]?.latest_podcast_date))

    watch(selectedPodcastersIds, () => {
        const sortedOldestPodcasters = selectedPodcasters.value.sort(
            (a, b) => new Date(a.oldest_podcast_date || 'now').getFullYear() - new Date(b.oldest_podcast_date).getFullYear()
        )

        const sortedLatestPodcasters = selectedPodcasters.value.sort(
            (a, b) => new Date(a.oldest_podcast_date || 'now').getFullYear() - new Date(b.oldest_podcast_date).getFullYear()
        )

        const from = new Date(sortedOldestPodcasters[0]?.oldest_podcast_date || 'now').getFullYear()
        const till = new Date(sortedLatestPodcasters[0]?.latest_podcast_date || 'now').getFullYear()

        yearRange.value = {
            from,
            till,
        }
    })

    function resetPodcasters() {
        podcasters.value = []
        currentPage.value = 0
        scrollState.value = ScrollState.Idle
    }

    watch(
        () => categoryStore.selectedCategory,
        async () => {
            resetPodcasters()
            await fetchPodcasters()
        }
    )

    const conversationStarters: ComputedRef<ConversationStarterResource[]> = computed(() => {
        return selectedPodcasters.value[0]?.conversation_starters || []
    })

    const navigateToPodcaster = async (podcaster: PodcasterResource) => {
        try {
            let url: string

            if (selectedCategory.value?.slug) {
                url = route('clipfinder.podcaster.category.show', [podcaster.slug, selectedCategory.value.slug], undefined, Ziggy)
            }
            else
            {
                url = route('clipfinder.podcasters.show', [podcaster.slug], undefined, Ziggy)
            }

            if (!url) {
                return
            }

            await router.visit(url, {
                preserveState: true,
                preserveScroll: true,
            })
        } catch (error) {
            scrollState.value = ScrollState.Failed
        }
    }

    function toggle() {
        open.value = !open.value
    }

    function close() {
        open.value = false
    }

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    return {
        fetchAllPodcasters,
        toggle,
        close,
        open,
        yearRange,
        podcasters,
        selectedPodcasters,
        selectedPodcastersIds,
        selectPodcasters,
        navigateToPodcaster,
        trainingDate,
        conversationStarters,
        isWaiting,
        failed,
        loadMorePodcasters,
        resetPodcasters,
        podcasterCount,
        updatePodcasterFollowStatus,
        toggleFollowPodcaster,
        fetchFollowedPodcasters,
        followedPodcasters,
        isLoading,
        error,
        selectedPodcasterWithQuestions,
        setSelectedPodcasterWithQuestions,
        fetchPodcasterQuestions,
        selectAllPodcasters
    }
})
