import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import useApi from '@/Composables/useApi'
import { useCategoryStore } from '@/Stores/category'
import type { ClipResource, PodcastResource } from '@/types'

export enum ScrollState {
    Idle = 'idle',
    Requesting = 'requesting',
    Ended = 'ended',
    Failed = 'failed',
}

export interface TimelineItem {
    type: 'clip' | 'podcast'
    data: ClipResource | PodcastResource
    date: string
}

export const useTimelineStore = defineStore('timeline', () => {
    const categoryStore = useCategoryStore()
    const timelineItems = ref<TimelineItem[]>([])
    const scrollState = ref<ScrollState>(ScrollState.Idle)
    const nextCursor = ref<string | null>(null)
    const isLoading = ref(false)

    const { getJson } = useApi()

    const fetchTimelineItems = async (podcaster: string | null = null, scoreThreshold: string | null = null, hideWhenEmpty: boolean | null = null) => {
        try {
            if (scrollState.value !== ScrollState.Idle) {
                return
            }

            scrollState.value = ScrollState.Requesting
            isLoading.value = true

            const categoryId = categoryStore.selectedCategory?.id
            let url = `/api/clips/timeline?limit=10`

            if (timelineItems.value.length > 0 && nextCursor.value) {
                url += `&cursor=${nextCursor.value}`
            }

            if (categoryId) {
                url += `&category=${categoryId}`
            }

            if (podcaster) {
                url += `&podcaster=${podcaster}`
            }

            if (scoreThreshold) {
                url += `&score_threshold=${scoreThreshold}`
            }

            if (hideWhenEmpty) {
                url += `&hide_when_empty=${hideWhenEmpty}`
            }

            const response = await getJson(url)

            if (response.data && Array.isArray(response.data)) {
                timelineItems.value = [...timelineItems.value, ...response.data]
                nextCursor.value = response.next_cursor

                if (!response.next_cursor) {
                    scrollState.value = ScrollState.Ended
                } else {
                    scrollState.value = ScrollState.Idle
                }
            } else {
                console.error('Timeline response data is not an array:', response)
                scrollState.value = ScrollState.Failed
            }
        } catch (e) {
            scrollState.value = ScrollState.Failed
            console.error('Failed to load timeline:', e)
        } finally {
            isLoading.value = false
        }
    }

    const resetTimeline = () => {
        timelineItems.value = []
        scrollState.value = ScrollState.Idle
        nextCursor.value = null
        isLoading.value = false
    }

    const isWaiting = computed(() => scrollState.value === ScrollState.Requesting)
    const failed = computed(() => scrollState.value === ScrollState.Failed)

    return {
        timelineItems,
        scrollState,
        isLoading,
        isWaiting,
        failed,
        fetchTimelineItems,
        resetTimeline,
    }
})
