import type { CategoryResource } from '@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import useApi from '@/Composables/useApi'
import { usePage } from '@inertiajs/vue3'

const { getJson } = useApi()

const initialState = {
    categories: [] as CategoryResource[],
    selectedCategory: null as CategoryResource | null,
    error: null as string | null,
    isLoading: false
}

export async function defineCategoryStore() {
    const store = useCategoryStore()
    await store.fetchAllCategories()
    return store
}

export const useCategoryStore = defineStore('category', () => {
    const page = usePage()
    const categories = ref<CategoryResource[]>([])
    const selectedCategory = ref<CategoryResource | null>(null)
    const isLoading = ref(false)
    const error = ref<string | null>(null)

    if (page.props.category) {
        selectedCategory.value = page.props.category as CategoryResource
    }

    const parentCategories = computed(() => categories.value.filter((p) => p.parent_id === null))

    function resetStore() {
        categories.value = initialState.categories
        selectedCategory.value = initialState.selectedCategory
        error.value = initialState.error
        isLoading.value = initialState.isLoading
    }

    function $reset() {
        resetStore()
    }

    async function fetchCategories(endpoint: string) {
        isLoading.value = true
        error.value = null
        try {
            categories.value = await getJson(endpoint)
        } catch (err) {
            error.value = 'Failed to fetch categories'
        } finally {
            isLoading.value = false
        }
    }

    async function fetchAllCategories() {
        if (categories.value.length === 0) {
            await fetchCategories('/api/categories')
        }
    }

    async function fetchPodcasterCategories(podcasterId: string) {
        await fetchCategories(`/api/podcaster/${podcasterId}/categories`)
    }

    async function fetchPodcasterClipCategories(podcasterId: string) {
        await fetchCategories(`/api/podcaster/${podcasterId}/clipCategories`)
    }

    function setSelectedCategory(category: CategoryResource | null) {
        selectedCategory.value = category
    }

    return {
        categories,
        parentCategories,
        selectedCategory,
        isLoading,
        error,
        fetchAllCategories,
        fetchPodcasterCategories,
        fetchPodcasterClipCategories,
        setSelectedCategory,
        reset: resetStore,
        $reset,
    }
})
